<template>
  <sticky-cont
    class="step-navigation-buttons"
    :class="{'step-navigation-buttons--only-confirm': onlyConfirm}"
  >
    <p v-if="oneMoreStep" class="step-navigation-buttons__note">
      You’re almost there! Just one more step
    </p>
    <hs-button
      v-if="hasBackButton"
      class="step-navigation-buttons__button"
      :class="{
        'step-navigation-buttons__button--back': ['Back', 'Cancel'].includes(backButtonText),
      }"
      size="md"
      variant="secondary"
      value="back"
      @click="$emit('back')"
      :style="buttonStyles"
      :disabled="disabledBack"
      type="button"
      >{{ backButtonText }}</hs-button
    >
    <hs-button
      class="step-navigation-buttons__button"
      :class="{
        'step-navigation-buttons__button--next': ['Next', 'Continue'].includes(confirmButtonText),
      }"
      size="md"
      value="submit"
      @click="$emit('confirm')"
      :disabled="disabled"
      :style="buttonStyles"
      type="submit"
      >{{ confirmButtonText }}</hs-button
    >
  </sticky-cont>
</template>
<script>
import StickyCont from '@/components/shared/FormStickyActionCont';

export default {
  props: {
    confirmButtonText: {
      type: String,
      required: true,
    },
    backButtonText: {
      type: String,
      default: 'Back',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledBack: {
      type: Boolean,
      default: false,
    },
    hasBackButton: {
      type: Boolean,
      default: true,
    },
    mobileButtonWidth: {
      type: Number,
      default: 215,
    },
    oneMoreStep: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StickyCont,
  },
  computed: {
    buttonStyles() {
      const {mobileButtonWidth} = this;
      return {
        width: `${mobileButtonWidth}px`,
      };
    },
    onlyConfirm() {
      return this.confirmButtonText && !this.hasBackButton;
    },
  },
};
</script>
<style lang="scss" scoped>
.step-navigation-buttons {
  flex-direction: column-reverse;

  @include mf($gridSMBreakpoint) {
    flex-direction: row;
    justify-content: space-between;

    &--only-confirm {
      justify-content: center;
    }
  }

  &__button {
    margin: 0;

    @include mf($gridSMBreakpoint) {
      margin: 0 1.3%;
    }

    @include mf($gridMDBreakpoint) {
      // important is needed here
      // to reset dynamic width value
      // that's being derived from a numeric prop
      // for example in StepNavigationButtons component
      width: unset !important;
    }

    &--back,
    &--next {
      min-width: 152px;
    }
  }
  &__note {
    order: 1;
    width: 100%;
    margin-top: 0;
    margin-bottom: 8px;
    color: $darkGray;
    font-size: 16px;
    text-align: center;

    @include mf($gridSMBreakpoint) {
      order: initial;
      margin-top: -10px;
      margin-right: 10px;
      text-align: right;
    }
  }
}
</style>
